<div class="select">
  <mat-form-field
    [class]="!isMaterialLabel ? 'custom-label' : ''"
    appearance="outline"
  >
    <mat-label *ngIf="isMaterialLabel && label">{{ label }}</mat-label>
    <mat-select
      [formControl]="control"
      [value]="control.value"
      [id]="inputId"
      [multiple]="isMultiSelect"
      [placeholder]="placeholder"
    >
      <mat-select-trigger>
        <span *ngIf="allSelected?.selected; else notAllSelected">All</span>
        <ng-template #notAllSelected>{{ selectedOptions }}</ng-template>
      </mat-select-trigger>
      <mat-option
        #allSelected
        (click)="toggleAllSelection()"
        *ngIf="showAllOption"
        [value]="0"
      >
        All
      </mat-option>
      <mat-option
        (click)="optionClicked()"
        *ngFor="let option of options"
        [value]="option.value"
        class="select__option"
      >
        @if (isMultiline) {
          <div class="select__option__title">{{ option.viewValueTitle }}</div>
          <span
            *ngFor="let viewValue of option.viewValueArray"
            class="select__option__view-value"
          >
            {{ viewValue }}
          </span>
        } @else {
          {{ option.viewValueTitle || option.viewValueArray[0] || '' }}
        }
      </mat-option>
      <mat-option *ngIf="additionalOption" [value]="additionalOption.value">
        <span class="select__option__additional-option">{{
          additionalOption.viewValue
        }}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>
</div>
