import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectOption<T = any> {
  value: T;
  viewValueTitle?: string;
  viewValueArray: Array<string>;
}

export interface AdditionalSelectOption {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [MatSelectModule, ReactiveFormsModule, NgIf, NgForOf, AsyncPipe],
})
export class SelectComponent {
  @Input() public control = new FormControl<
    number | string | null | Array<number | string | null>
  >(null);
  @Input() public label = '';
  @Input() public isMaterialLabel = true;
  @Input() public inputId = '';
  @Input() public isMultiSelect = false;
  @Input() public options: Array<SelectOption> = [];
  @Input() public showAllOption = false;
  @Input() public isMultiline = false;
  @Input() public additionalOption: AdditionalSelectOption | null = null;
  @Input() public placeholder = '';
  @Output() public selectedOptionEmitter = new EventEmitter<
    number | string | Array<number | string | null> | Boolean | null
  >();

  @ViewChild('allSelected') public allSelected: MatOption | undefined;

  public get selectedOptions(): string {
    if (
      this.additionalOption &&
      this.control.value === this.additionalOption.value
    ) {
      return this.additionalOption?.viewValue ?? 'undefined_label';
    }

    return this.options
      .filter(
        (option) =>
          this.control.value === option.value ||
          (Array.isArray(this.control.value) &&
            this.control.value.includes(option.value)),
      )
      .map((option) => {
        const title = option.viewValueTitle ?? '';
        const subtitle = option.viewValueArray.join(' ');
        if (this.isMultiline) {
          return `${title} ${subtitle}`.trim();
        }
        return subtitle;
      })
      .join(', ');
  }

  @Input()
  public set isDisabled(disabled: boolean) {
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public optionClicked(): void {
    this._markControl();

    if (
      this.isMultiSelect &&
      this.showAllOption &&
      Array.isArray(this.control.value)
    ) {
      if (this.allSelected?.selected) {
        this.allSelected?.deselect();
        return;
      }
      if (this.control.value.length === this.options.length) {
        this.allSelected?.select();
      }
    }

    this.selectedOptionEmitter.emit(this.control.value);
  }

  public toggleAllSelection(): void {
    this._markControl();

    if (this.allSelected?.selected) {
      this.selectedOptionEmitter.emit(this.allSelected.selected);
      this.control.patchValue([
        0,
        ...this.options
          .filter((option) => option.value !== null)
          .map((option) => option.value),
      ]);
      return;
    }

    this.control.patchValue([]);
  }

  private _markControl(): void {
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }
}
